/* Login.css */
.loginForm {
  width: 300px;
  margin: 0 auto;
  /* padding: 20px 0px; */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}

.pv-logo {
  text-align: center;
  margin: 35px 0px;
}
.pv-logo img {
  width: 75%;
}

label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

