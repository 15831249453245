/* Order CSS */
.order-card {
    padding: 20px;
    border-radius: 8px;
    background-color: #fff;
    cursor: pointer;
    transition: box-shadow 0.3s ease;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    color: #333333;
  }
  .order-card:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  }