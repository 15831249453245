.layout-container {
  max-width: 960px;
  margin: 0 auto;
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: #fff;
  padding: 10px;
}

.logo {
  width: 75px;
}

.nav-list {
  list-style: none;
  display: flex;
  gap: 20px;
  padding: 0;
  margin-top: 8px;
}

.nav-item {
  margin: 0;
}

.nav-item-name {
  margin-left: auto;
}
.nav-link {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;
}

.nav-link:hover {
  color: #ff5733;
}

.product-scraper {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

form {
  margin-top: 20px;
}

label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  margin-top: 10px;
  padding: 8px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.previewImage {
  object-fit: cover;
  width: 100%;
}

/* Upload Image */
/* UploadImage.css */

.dropzone {
  border: 2px dashed #ccc;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dropzone p {
  margin: 0;
  font-size: 16px;
}

.uploaded-image {
  margin-top: 20px;
  text-align: center;
}

.uploaded-image img {
  max-width: 100%;
  max-height: 300px;
  border: 1px solid #ddd;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

/* ProductUpload Form */
/* UploadProduct.css */
.upload-product-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.product-form {
  width: 400px;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.product-form h2 {
  margin-bottom: 15px;
}

.product-form label {
  display: block;
  margin-bottom: 10px;
}

.category-checkboxes label {
  display: block;
  margin-bottom: 5px;
}

.product-form input,
.product-form textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 14px;
  margin-bottom: 10px;
}

.product-form button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 8px 12px;
  cursor: pointer;
}

.product-form button:hover {
  background-color: #0056b3;
}

/* Inlien checkboxes */
.inline-checkboxes {
  display: flex;
  flex-wrap: wrap; /* Wrap the checkboxes to the next line if they exceed container width */
  gap: 10px; /* Adjust the gap as needed */
  width: 100%; /* Set a width to contain the checkboxes */
}

.inline-checkbox-label {
  background: #ccc;
  padding: 3px;
  text-align: center;
}

.inline-checkbox {
  display: flex;
  align-items: center;
  width: 50%; /* Each inline-checkbox takes 50% width to have two checkboxes in each row */
  margin-bottom: 10px; /* Add margin at the bottom of each inline-checkbox */
}

.inline-checkbox input[type="checkbox"] {
  margin-right: 5px;
}

/* Preview Product */
.product-preview {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
}

.product-image {
  flex: 0 0 200px;
  margin-right: 20px;
}

.product-image img {
  max-width: 350px;
  object-fit: cover;
  height: auto;
}

.product-details {
  flex: 1;
}

.product-details h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.product-details p {
  margin-bottom: 10px;
}

.short-description ul {
  list-style: none;
  padding-left: 0;
}

.short-description li {
  margin-bottom: 5px;
}

.categories ul {
  list-style: none;
  padding-left: 0;
}

.categories li {
  display: inline;
  margin-right: 10px;
}

.tags p {
  margin-top: 10px;
}

/* Layout Toggle Button */
.menu-toggle-button {
  padding: 5px 10px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 8px;
  margin-left: 60px;
}

/* Navbar set Position */
/* #navbar-toggle {
  position: absolute;
  background-color: #ddd;
  padding: 15px;
  z-index: 999;
  border-radius: 4px;
  color: #333;
  width: max-content;
  right: 0px;
} */

/* Show/hide elements based on screen size */
@media (max-width: 768px) {
  /* Hide nav list for larger screens */
  .nav-list {
      display: none;
  }
  .nav-list.open {
      display: block;
      margin-top: 0px;
  }
  #navbar-toggle {
    position: absolute;
    background-color: #ddd;
    padding: 15px;
    z-index: 999;
    border-radius: 4px;
    color: #333;
    font-weight: 500;
    width: max-content;
    right: 15px;
  }
}
@media (min-width: 769px) {
  /* Hide toggle button and dropdown for larger screens */
  .mobile-menu-wrapper {
      display: none;
  }
  .menu-toggle-button {
    display: none;
  }
}