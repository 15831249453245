.file-upload-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
  margin: 25px;
}

#file-upload {
  display: none;
}

.preview-img {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  width: 100%;
}

.file-upload-label {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
}

.btn-upload {
  text-align: center;
  margin: 20px;
}

.file-upload-info {
  margin-left: 10px;
  font-size: 14px;
  color: #333;
}

/* Added to style the image preview wrapper */
.image-preview-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

/* Added to style the preview images */
.image-preview-wrapper img {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100px;
  height: auto;
}
